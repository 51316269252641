const currencyFormats = {
    EUR: {
        label: '€',
    },
    JPY: {
        label: '¥',
        decimals: 0,
    },
};


function formatMoney(amount, currency, label = null) {
    const format = currencyFormats[currency] || {};
    const decimals = format.decimals === undefined ? 2 : format.decimals;
    if (label === null) {
        label = format.label || currency;
    }

    return `${amount.toFixed(decimals)} ${label}`;
}


export {
    currencyFormats,
    formatMoney,
};
