import React, {Component} from 'react';

import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import {APP_MODE_ADD, APP_MODE_IMPORT, APP_MODE_LIST, APP_MODE_RULES} from "../utils/constants";
import AddExpenseView from "./AddExpenseView";
import ListView from "./ListView";
import ImportView from "./ImportView";
import RulesView from "./RulesView";


export default class MainView extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    renderNavbarTab(mode, label) {
        const isActive = mode === this.props.activeMode;
        return (
            <NavItem>
                <NavLink active={isActive} href="#" onClick={() => this.props.onSetActiveMode(mode)}>
                    {label}
                </NavLink>
            </NavItem>
        );
    }

    renderNavbar() {
        return (
            <Navbar className="mb-3" color="dark" dark expand={true}>
                <NavbarBrand href="/">Hydrus</NavbarBrand>
                <Nav className="ml-auto" navbar>
                    {this.renderNavbarTab(APP_MODE_ADD, "Add")}
                    {this.renderNavbarTab(APP_MODE_LIST, "List")}
                    {this.renderNavbarTab(APP_MODE_IMPORT, "Import")}
                    {this.renderNavbarTab(APP_MODE_RULES, "Rules")}
                    <NavItem>
                        <span className="navbar-text ml-3">{this.props.username}</span>
                    </NavItem>
                </Nav>
            </Navbar>
        );
    }

    renderActiveView() {
        if (this.props.activeMode === APP_MODE_ADD) {
            return (
                <AddExpenseView
                    onAddExpense={this.props.onAddExpense}
                />
            );
        } else if (this.props.activeMode === APP_MODE_LIST) {
            return (
                <ListView
                    expenses={this.props.expenses}
                    setEarliestRequestedTimestamp={this.props.setEarliestRequestedTimestamp}
                    onEditExpense={this.props.onEditExpense}
                />
            );
        } else if (this.props.activeMode === APP_MODE_IMPORT) {
            return (
                <ImportView
                    onImportData={this.props.onImportData}
                />
            );
        } else if (this.props.activeMode === APP_MODE_RULES) {
            return (
                <RulesView
                    rules={this.props.rules}
                    onAddRule={this.props.onAddRule}
                    onEditRule={this.props.onEditRule}
                    onDeleteRule={this.props.onDeleteRule}
                />
            );
        }
    }

    render() {
        return (
            <div>
                {this.renderNavbar()}
                {this.renderActiveView()}
            </div>
        );
    }
}
