import React, {Component} from 'react';

import {Formik, Field, Form, ErrorMessage} from 'formik';
import {Button} from 'reactstrap';

import {ReactstrapNumberField, ReactstrapTextField} from "../forms/fields";


export default class AddExpenseView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submittedValues: {},
            serverErrors: {},
        };

        this.validateFormValues = this.validateFormValues.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onAmountFocused = this.onAmountFocused.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    onAmountFocused(e) {
        e.target.select();
    }

    transformApiErrors(data) {
        let ret = {};
        data.errors.forEach(err => {
            if (err.source && err.source.pointer && err.source.pointer.startsWith("/data/attributes/")) {
                const field = err.source.pointer.substr("/data/attributes/".length);
                ret[field] = err.detail;
            }
        });

        return ret
    }

    validateFormValues(values) {
        let errors = {};
        Object.keys(values).forEach(k => {
            if (values[k] === this.state.submittedValues[k] && this.state.serverErrors[k]) {
                errors[k] = this.state.serverErrors[k];
            }
        });
        return errors;
    }

    async onSubmit(values, actions) {
        console.log("Submit:", values);
        actions.setStatus({});
        this.setState({submittedValues: values});
        const result = await this.props.onAddExpense(values);
        console.log("onSubmit(): result:", result);

        actions.setSubmitting(false);
        if (result.ok) {
            actions.resetForm({amount: '', description: ''});
            actions.setStatus({msg: 'Expense added'});
        } else {
            const errors = this.transformApiErrors(await result.json());
            console.log("Setting form errors to ", errors);
            this.setState({serverErrors: errors});
            Object.keys(errors).forEach(k => {
                actions.setFieldError(k, errors[k]);
                actions.setFieldTouched(k, true, false);
            });
        }
    }

    renderForm({errors, touched, isSubmitting, status}) {
        return (
            <Form>
                <ReactstrapNumberField name="amount" label="Amount" step={0.01} onFocus={this.onAmountFocused} />
                <ReactstrapTextField name="description" label="Description" />

                {status && status.msg && <p>{status.msg}</p>}
                <Button type="submit" color="primary" disabled={isSubmitting}>
                    Add expense
                </Button>
            </Form>

        );
    }

    render() {
        return (
            <div className="container">
                <h2>Add Expense</h2>

                <Formik
                    initialValues={{}}
                    onSubmit={this.onSubmit}
                    render={this.renderForm}
                    validate={this.validateFormValues}
                />
            </div>
        );
    }
};
