import React, {Component} from 'react';

import _orderBy from 'lodash-es/orderBy';
import {Input} from 'reactstrap';

import {RulesList} from "../components/RulesList";


export default class RulesView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ordering: null,
            filterText: '',
        };

        this.listRef = React.createRef();

        this.onAddRule = this.onAddRule.bind(this);
        this.onOrderingChanged = this.onOrderingChanged.bind(this);
        this.onFilterTextChanged = this.onFilterTextChanged.bind(this);
    }

    getFilteredRules() {
        let rules = this.props.rules;

        // Filter by text
        if (this.state.filterText) {
            const tokens = this.state.filterText.toLowerCase().split(/ +/);
            tokens.forEach(token => {
                if (!token.length) {
                    return;
                }
                if (token[0] === '-') {
                    token = token.substr(1);
                    rules = rules.filter(rule => {
                        return rule.attributes.additional_description.toLowerCase().indexOf(token) === -1 &&
                               rule.attributes.regex.toLowerCase().indexOf(token) === -1;
                    });
                } else {
                    rules = rules.filter(rule => {
                        return rule.attributes.additional_description.toLowerCase().indexOf(token) !== -1 ||
                               rule.attributes.regex.toLowerCase().indexOf(token) !== -1;
                    });
                }
            });
        }

        // Sort
        const ordering = this.state.ordering;
        if (ordering !== null) {
            rules = _orderBy(rules, `attributes.${ordering[0]}`, ordering[1]);
        }

        return rules;
    }

    async onAddRule() {
        await this.props.onAddRule();
        this.listRef.current.openCellEditor(0, 0);
    }

    onOrderingChanged(spec) {
        this.setState({ordering: spec});
    }

    onFilterTextChanged(e) {
        this.setState({filterText: e.target.value});
    }

    render() {
        const rules = this.getFilteredRules();

        return (
            <div>
                <div className="mx-3 clearfix">
                    <h2 className="float-left">Rules</h2>

                    <Input className="ml-4 d-inline-block w-auto" type="search" placeholder="Filter" onChange={this.onFilterTextChanged} value={this.state.filterText} />

                    <button className="btn btn-secondary btn-sm float-right" onClick={this.onAddRule}>
                        Add Rule
                    </button>
                </div>

                <RulesList
                    ref={this.listRef}
                    rules={rules}
                    onEditRule={this.props.onEditRule}
                    onDeleteRule={this.props.onDeleteRule}
                    onOrderingChanged={this.onOrderingChanged}
                />
            </div>
        );
    }
}
