import {
    AuthorizationNotifier,
    AuthorizationRequest,
    BaseTokenRequestHandler, BasicQueryStringUtils, GRANT_TYPE_AUTHORIZATION_CODE, GRANT_TYPE_REFRESH_TOKEN,
    RedirectRequestHandler, TokenRequest,
} from "@openid/appauth";
import {AuthorizationServiceConfiguration} from "@openid/appauth/built/authorization_service_configuration";
import {LocalStorageBackend} from "@openid/appauth/built/storage";
import {TokenResponse} from "@openid/appauth/built/token_response";


/**
 * Glue code between AppAuth which expects jQuery XHR-like interface, and us, who want to use fetch().
 *
 * Note that this supports only the bare minimum feature set to get OAuth working.
 */
class FetchRequestor {
    xhr(settings) {
        return fetch(settings.url, {
            method: settings.method,
            headers: settings.headers,
            body: settings.data,
        }).then(response => response.json());
    }
}


/**
 * Workaround for AppAuth trying to always read state/code/etc params from hash part of the url, not queryParams.
 */
class NoHashQueryStringUtils extends BasicQueryStringUtils {
    parse(input, useHash) {
        return super.parse(input, false);
    }
}


export const OAUTH_STATE_INITIALIZING = 'init';
export const OAUTH_STATE_AUTHENTICATED = 'authenticated';
export const OAUTH_STATE_NOT_AUTHENTICATED = 'anonymous';


export class OAuthFlow {
    STORED_TOKEN_KEY = 'oauth-tokenResponse';

    constructor(config = {url, clientId, redirectUri, scope}, onStateChangedCb = null) {
        console.log("OAuthFlow()");

        this.config = config;

        // Set auth state to initializing
        this.state = OAUTH_STATE_INITIALIZING;
        this.isTokenRequestInProgress = false;
        this.onStateChangedCb = onStateChangedCb;

        this.init();
        console.log("OAuthFlow() done");
    }

    async init() {
        console.log("OAuthFlow.init()");
        // Init storage and try to load previously saved tokens
        this.storage = new LocalStorageBackend();
        this.tokenResponse = window.localStorage.getItem(this.STORED_TOKEN_KEY);
        console.log("OAuthFlow.init(): loaded saved tokenResponse:", this.tokenResponse);
        if (this.tokenResponse) {
            this.tokenResponse = new TokenResponse(JSON.parse(this.tokenResponse));
            console.log("OAuthFlow.init(): loaded previous token:", this.tokenResponse);
        }

        // Figure out if we're authenticated or in the process of completing it
        if (this.tokenResponse && this.tokenResponse.accessToken) {
            // Auth token was loaded, so all good
            console.log("OAuthFlow.init(): have access token, changing state to authenticated");

        } else {
            // Maybe we received a redirect to complete authorization?
            let params = (new URL(document.location)).searchParams;
            if (params.get('code') && params.get('state')) {
                console.log('OAuthFlow.init(): Potential auth redirect detected, trying to auto-complete');
                await this.completeAuthorization();
            }
        }
        this.checkState();

        console.log("OAuthFlow.init() done");
    }

    onStateChanged(onStateChangedCb) {
        this.onStateChangedCb = onStateChangedCb;
        if (this.state !== OAUTH_STATE_INITIALIZING) {
            this.onStateChangedCb(this.state);
        }
    }

    checkState() {
        let state = OAUTH_STATE_INITIALIZING;
        if (this.tokenResponse && this.tokenResponse.accessToken) {
            state = OAUTH_STATE_AUTHENTICATED;
        } else if (!this.isTokenRequestInProgress) {
            state = OAUTH_STATE_NOT_AUTHENTICATED;
        }

        if (state !== this.state) {
            console.log("checkState(): changing state to", state);
            this.state = state;
            if (this.onStateChangedCb) {
                this.onStateChangedCb(this.state);
            }
        }
    }

    accessToken() {
        if (this.state !== OAUTH_STATE_AUTHENTICATED || !this.tokenResponse) {
            return null;
        }
        return this.tokenResponse.accessToken;
    }

    async initAppAuth() {
        console.log("initAppAuth()");
        if (this.notifier && this.authorizationHandler && this.service_configuration) {
            console.log("initAppAuth(): everything already there");
            return;
        }

        // Initialize AppAuth
        this.notifier = new AuthorizationNotifier();
        this.notifier.setAuthorizationListener(this.onAuthorizationComplete.bind(this));

        // uses a redirect flow
        this.authorizationHandler = new RedirectRequestHandler(undefined, new NoHashQueryStringUtils());
        this.authorizationHandler.setAuthorizationNotifier(this.notifier);

        const req = new FetchRequestor();
        try {
            const configuration = await AuthorizationServiceConfiguration.fetchFromIssuer(this.config.url, req);
            console.log('initAppAuth(): Fetched service configuration', configuration);
            this.service_configuration = configuration;

        } catch (error) {
            console.log('initAppAuth(): Something bad happened', error);
            throw error;
        }
    }

    async startAuthorization(scope = null, nextUrl = null) {
        console.log("startAuthorization()");
        await this.initAppAuth();

        // create a request
        let request = new AuthorizationRequest({
            client_id: this.config.clientId,
            redirect_uri: this.config.redirectUri,
            scope: scope || this.config.scope,
            response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
            state: undefined,
            extras: {'prompt': 'consent', 'access_type': 'offline'},
            internal: {nextUrl: nextUrl},
        });

        // make the authorization request
        console.log("startAuthorization(): starting request");
        this.authorizationHandler.performAuthorizationRequest(this.service_configuration, request);
        console.log("startAuthorization(): done");
    }

    async completeAuthorization() {
        console.log("completeAuthorization()");
        try {
            await this.initAppAuth();
            const result = await this.authorizationHandler.completeAuthorizationRequestIfPossible();
            console.log("completeAuthorization(): completed:", result);
        } catch (e) {
            console.log("completeAuthorization(): error:", e);
            throw e;
        }
    }

    async onAuthorizationComplete(request, response, error) {
        console.log('onAuthorizationComplete()', request, response, error);
        if (!response) {
            return;
        }

        await this.requestTokenWithCode(response.code, request.internal.code_verifier);
        console.log("onAuthorizationComplete(): all done");

        // If we have saved nextUrl, redirect there
        if (request.internal && request.internal.nextUrl) {
            const nextUrl = request.internal.nextUrl;
            console.log("onAuthorizationComplete(): have nextUrl, redirecting to", nextUrl);
            window.location = nextUrl;
        } else {
            this.checkState();
        }
    }

    async requestTokenWithCode(code, code_verifier) {
        const request = new TokenRequest({
            client_id: this.config.clientId,
            redirect_uri: this.config.redirectUri,
            grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
            code: code,
            refresh_token: undefined,
            extras: {
                code_verifier: code_verifier,
            },
        });

        await this.makeTokenRequest(request);
    }

    async requestTokenWithRefreshToken(refreshToken) {
        const request = new TokenRequest({
            client_id: this.config.clientId,
            redirect_uri: this.config.redirectUri,
            grant_type: GRANT_TYPE_REFRESH_TOKEN,
            code: undefined,
            refresh_token: refreshToken,
            extras: undefined,
        });

        await this.makeTokenRequest(request);
    }

    async makeTokenRequest(request) {
        console.log("makeTokenRequest()");
        this.isTokenRequestInProgress = true;
        try {
            const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
            const response = await tokenHandler.performTokenRequest(this.service_configuration, request);
            console.log("makeTokenRequest(): Got response", response);
            this.isTokenRequestInProgress = false;

            if (this.tokenResponse) {
                // copy over new fields
                this.tokenResponse.accessToken = response.accessToken;
                this.tokenResponse.issuedAt = response.issuedAt;
                this.tokenResponse.expiresIn = response.expiresIn;
                this.tokenResponse.tokenType = response.tokenType;
                this.tokenResponse.scope = response.scope;
            } else {
                this.tokenResponse = response;
            }
            console.log("makeTokenRequest(): accessToken: ", this.tokenResponse.accessToken);
            console.log("makeTokenRequest(): refreshToken: ", this.tokenResponse.refreshToken);

            this.storage.setItem(this.STORED_TOKEN_KEY, JSON.stringify(this.tokenResponse.toJson()));

        } catch (error) {
            this.isTokenRequestInProgress = false;
            console.log("makeTokenRequest(): error", error);
        }
        console.log("makeTokenRequest(): done");
    }

    async accessTokenExpired() {
        if (this.tokenResponse.refreshToken) {
            console.log("accessTokenExpired(): trying to recover with refresh token");
            await this.requestTokenWithRefreshToken(this.tokenResponse.refreshToken);
            return true;

        } else {
            // Invalidate and delete saved tokens
            console.log("accessTokenExpired(): no refresh token, signing out");
            window.localStorage.removeItem(this.STORED_TOKEN_KEY);
            this.tokenResponse = null;
            this.checkState();
            return false;
        }
    }
}
