import React from 'react';
import PropTypes from 'prop-types';


function AppLoadingIndicator({text="Loading..."}) {
    return (
        <div className="loading-indicator loading-indicator-app h-100 d-flex align-items-center justify-content-center">
            {text}
        </div>
    );
}
AppLoadingIndicator.propTypes = {
    text: PropTypes.string,
};


export default AppLoadingIndicator;
