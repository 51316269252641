import React, {Component} from 'react';

import {Button, ButtonGroup, Input} from 'reactstrap';

import {BULK_EDIT_ADD_AS_FIRST_TAG, BULK_EDIT_ADD_AS_LAST_TAG, BULK_EDIT_ADD_AT_BEGINNING} from "../utils/constants";


export default class BulkEditBar extends Component {
    renderModes() {
        const modes = [
            {
                mode: BULK_EDIT_ADD_AT_BEGINNING,
                label: "Beginning",
            },
            {
                mode: BULK_EDIT_ADD_AS_FIRST_TAG,
                label: "As first tag",
            },
            {
                mode: BULK_EDIT_ADD_AS_LAST_TAG,
                label: "End",
            },
        ];
        const modeButtons = modes.map(mode => {
            const isActive = this.props.mode === mode.mode;
            return (
                <Button key={mode.mode} active={isActive} onClick={e => {this.props.onModeChanged(mode.mode)}}>
                    {mode.label}
                </Button>
            );
        });

        return <ButtonGroup className="ml-3 d-inline-block">{modeButtons}</ButtonGroup>;
    }

    render() {
        return (
            <div className="d-flex px-3 py-2 bg-secondary text-white form-inline">
                <div className="flex-grow-1">
                    Add tags:
                    <Input
                        className="mx-3 bg-secondary text-white"
                        type="text"
                        onChange={this.props.onTextChanged}
                        value={this.props.text}
                    />

                    Add to:
                    {this.renderModes()}
                </div>

                <div>
                    <Button className="text-right" color="default" onClick={this.props.onApply}>Apply</Button>
                </div>
            </div>
        );
    }
}
