import React from 'react';

import isBefore from 'date-fns/is_before';
import subDays from 'date-fns/sub_days';

import {
    OAUTH_STATE_AUTHENTICATED,
    OAUTH_STATE_NOT_AUTHENTICATED,
    OAuthFlow,
} from "../utils/oauth";
import LoginView from "./LoginView";
import AppLoadingIndicator from "../components/AppLoadingIndicator";
import MainView from "./MainView";
import HydrusApi from "../utils/HydrusApi";
import {UnauthorizedException} from "../utils/api";
import {APP_MODE_ADD} from "../utils/constants";
import uuidv4 from "../utils/uuid";


const CURSOR_AT_END = Object();


class HydrusApp extends React.Component {
    OAUTH_REDIRECT_URI = location.origin;
    OAUTH_SCOPE = 'openid hydrus:read hydrus:write';

    constructor(props) {
        super(props);

        this.state = {
            activeMode: APP_MODE_ADD,
            isInitializing: true,
            isAuthenticated: false,
            expenses: [],
            rules: [],
        };
        this.expensesRequestInProgress = false;
        this.expensesCursor = null;
        this.requestExpensesUntil = subDays(new Date(), 30);

        this.setEarliestRequestedTimestamp = this.setEarliestRequestedTimestamp.bind(this);
        this.onAuthFlowStart = this.onAuthFlowStart.bind(this);
        this.onAuthFlowComplete = this.onAuthFlowComplete.bind(this);
        this.onAuthenticationStateChanged = this.onAuthenticationStateChanged.bind(this);
        this.onSetActiveMode = this.onSetActiveMode.bind(this);
        this.onAddExpense = this.onAddExpense.bind(this);
        this.onEditExpense = this.onEditExpense.bind(this);
        this.onImportData = this.onImportData.bind(this);
        this.onAddRule = this.onAddRule.bind(this);
        this.onEditRule = this.onEditRule.bind(this);
        this.onDeleteRule = this.onDeleteRule.bind(this);

        this.authFlow = new OAuthFlow({
            url: DJ_CONST.IDENTITY_ISSUER,
            clientId: DJ_CONST.IDENTITY_CLIENT_ID,
            redirectUri: this.OAUTH_REDIRECT_URI,
            scope: this.OAUTH_SCOPE,
        });
        this.api = new HydrusApi(this.authFlow, this.OAUTH_REDIRECT_URI);
    }

    componentDidMount() {
        this.authFlow.onStateChanged(this.onAuthenticationStateChanged);
    }

    onAuthenticationStateChanged(auth_state) {
        console.log("Auth state changed to", auth_state);
        if (auth_state === OAUTH_STATE_NOT_AUTHENTICATED) {
            this.setState({
                isInitializing: false,
                isAuthenticated: false,
            });
        } else if (auth_state === OAUTH_STATE_AUTHENTICATED) {
            // TODO: continue with data initialization
            this.loadUserData();
        }
    }

    async loadUserData() {
        console.log("getUserData()");
        try {
            const result = await this.api.userinfo();
            const data = await result.json();
            console.log("getUserData(): got", data);

            this.setState({username: data.data.username});
        } catch (e) {
            if (e === UnauthorizedException) {
                return;
            }
        }

        this.setState({
            isInitializing: false,
            isAuthenticated: true,
        });

        this.loadExpenses();
        this.loadRules();
    }

    async loadExpenses() {
        if (this.expensesRequestInProgress) {
            return;
        }

        this.expensesRequestInProgress = true;
        while (this.expensesCursor !== CURSOR_AT_END) {
            const expensesResult = await this.api.expenses(this.expensesCursor);
            const expenseData = await expensesResult.json();

            this.setState(state => ({
                expenses: [...state.expenses, ...expenseData.data],
            }));

            if (expenseData.data && expenseData.links && expenseData.links.next) {
                this.expensesCursor = expenseData.links.next;
            } else {
                this.expensesCursor = CURSOR_AT_END;
            }
            if (isBefore(expenseData.data[0].attributes.timestamp, this.requestExpensesUntil)) {
                break;
            }
        }
        this.expensesRequestInProgress = false;
    }

    async loadRules() {
        const rulesResult = await this.api.rules();
        const ruleData = await rulesResult.json();

        this.setState({
            rules: ruleData.data,
        });
    }

    setEarliestRequestedTimestamp(timestamp) {
        console.log("setEarliestRequestedTimestamp()", timestamp, this.requestExpensesUntil);
        if (isBefore(timestamp, this.requestExpensesUntil)) {
            console.log("setEarliestRequestedTimestamp(): updating TS and starting fetch");
            this.requestExpensesUntil = timestamp;
            this.loadExpenses();
        }
    }

    async onAuthFlowStart() {
        const nextUrl = window.location.toString();
        this.authFlow.startAuthorization(null, nextUrl);

    }
    async onAuthFlowComplete() {
        this.authFlow.completeAuthorization();
    }

    onSetActiveMode(mode) {
        this.setState({activeMode: mode});
    }

    async onAddExpense(data) {
        console.log("onAddExpense():", data);

        const expenseData = {
            timestamp: (new Date()).toISOString(),
            currency: 'EUR',
            ...data,
        };
        const result = await this.api.addExpense(expenseData);
        console.log("onAddExpense(): result", result);
        return result;
    }

    async onEditExpense(expenseId, updates) {
        console.log("onEditExpense():", expenseId, updates);

        this.setState((prevState, props) => {
            const expenses = prevState.expenses.map(expense => {
                if (expense.id !== expenseId) {
                    return expense;
                }
                return {
                    ...expense,
                    attributes: {...expense.attributes, ...updates},
                }
            });
            return {expenses};
        });

        // TODO: handle errors
        await this.api.updateExpense(expenseId, updates);
    }

    async onImportData(data) {
        console.log("onImportData():", data);

        const formData = new FormData();
        formData.append('type', data.type);
        formData.append('file', data.file);

        const result = await this.api.importData(formData);
        console.log("onImportData(): result", result);

        // Nuke current expenses state and start data reloading in the background
        this.setState({
            expenses: [],
        });
        this.expensesCursor = null;

        this.loadExpenses();
        this.loadRules();

        return result;
    }

    async onAddRule() {
        const result = await this.api.createRule({
            priority: 10,
            regex: 'regex',
            additional_description: '#new_tag',
        });
        const data = await result.json();

        this.setState({rules: [data.data, ...this.state.rules]});
    }

    async onEditRule(ruleId, updates) {
        const rules = this.state.rules.map(rule => {
            if (rule.id !== ruleId) {
                return rule;
            }
            return {
                ...rule,
                attributes: {...rule.attributes, ...updates},
            }
        });
        this.setState({rules});

        // TODO: handle errors
        await this.api.updateRule(ruleId, updates);
    }

    async onDeleteRule(ruleId) {
        // TODO: handle errors
        await this.api.deleteRule(ruleId);
        this.setState({rules: this.state.rules.filter(rule => rule.id !== ruleId)});
    }

    render() {
        // return <div className="d-flex align-items-center justify-content-center">Just testing!</div>
        if (this.state.isInitializing) {
            return <AppLoadingIndicator />;
        } else if (!this.state.isAuthenticated) {
            return <LoginView onLogin={this.onAuthFlowStart} />;
        }

        return (
            <MainView
                username={this.state.username}
                activeMode={this.state.activeMode}
                onSetActiveMode={this.onSetActiveMode}
                onAddExpense={this.onAddExpense}
                onEditExpense={this.onEditExpense}
                expenses={this.state.expenses}
                onAddRule={this.onAddRule}
                onEditRule={this.onEditRule}
                onDeleteRule={this.onDeleteRule}
                rules={this.state.rules}
                onImportData={this.onImportData}
                setEarliestRequestedTimestamp={this.setEarliestRequestedTimestamp}
            />
        );
    }
}

export default HydrusApp;
