import React, {Component} from 'react';

import format from 'date-fns/format'
import parse from 'date-fns/parse'
import isSameDay from 'date-fns/is_same_day'

import DataGrid from "../components/DataGrid";
import {tokenizeHashtagText} from "../utils/text";


function AmountFormatter({value, row}) {
    const expense = row;

    const sign = (value > 0) ? (<b>+ </b>) : null;
    const approx = !expense.is_exact ? '~ ' : null;
    const amountStr = Math.abs(expense.amount).toFixed(2);

    return (
        <div className="text-right">
            {sign}{approx}{amountStr}
            <span className="text-muted"> {expense.currency}</span>
        </div>
    );
}


function TimestampFormatter({value, row}) {
    let fmt = "HH:mm";
    if (row.isNewDate) {
        fmt = "YYYY-MM-DD   " + fmt;
    }

    // Replace spaces with Unicode non-breaking spaces
    return (
        <div className="text-right">{format(value, fmt).replace(/ /g, "\u00a0")}</div>
    );
}


function DescriptionFormatter({value, row}) {
    const hasClickableTags = true;
    const tokens = tokenizeHashtagText(row.description);

    let descriptionFmt = [];
    tokens.forEach((token, i) => {
        if (hasClickableTags && token[0] === '#') {
            descriptionFmt.push(<a href="#" key={i} onClick={e => row.onTagClicked(token, e)}>{token}</a>);
        } else {
            descriptionFmt.push(token);
        }
        descriptionFmt.push(' ');
    });

    return descriptionFmt;
}


function IDFormatter({value}) {
    return <span className="text-muted">{value}</span>;
}


export default class ExpensesList extends Component {
    constructor(props) {
        super(props);

        this.getExpenseData = this.getExpenseData.bind(this);
        this.onTagClicked = this.onTagClicked.bind(this);
        this.onGridRowsUpdated = this.onGridRowsUpdated.bind(this);
        this.onRowsSelected = this.onRowsSelected.bind(this);
        this.onRowsDeselected = this.onRowsDeselected.bind(this);
        this.renderEmpty = this.renderEmpty.bind(this);
    }

    getExpenseData(index) {
        const expenseId = this.props.expenses[index].id;
        const expense = {
            ...this.props.expenses[index].attributes,
            id: expenseId,
            isNewDate: false,
            onTagClicked: this.onTagClicked,
            isSelected: this.props.selectedIds.indexOf(expenseId) !== -1,
        };

        const timestamp = parse(expense.timestamp);
        if (index === 0 || !isSameDay(timestamp, parse(this.props.expenses[index - 1].attributes.timestamp))) {
            expense.isNewDate = true;
        }

        return expense;
    }

    onTagClicked(tag, e) {
        e.preventDefault();
        if (this.props.onTagClicked) {
            this.props.onTagClicked(tag);
        }
    }

    onGridRowsUpdated({fromRow, toRow, updated}) {
        this.props.onEditExpense(this.props.expenses[fromRow].id, updated);
    }

    onRowsSelected(rows) {
        this.props.setSelectedIds(this.props.selectedIds.concat(
            rows.map(r => r.row.id),
        ));
    }

    onRowsDeselected(rows) {
        const rowIds = rows.map(r => r.row.id);
        this.props.setSelectedIds(this.props.selectedIds.filter(
            i => rowIds.indexOf(i) === -1,
        ));
    }

    renderEmpty() {
        return (
            <p className="lead text-center m-5">No matching expenses</p>
        );
    }

    render() {
        const columns = [
            {key: "timestamp", name: "Timestamp", width: 160, editable: true, formatter: TimestampFormatter},
            {key: "amount", name: "Amount", width: 140, editable: true, formatter: AmountFormatter},
            {key: "description", name: "Description", editable: true, formatter: DescriptionFormatter},
            {key: "id", name: <span className="text-muted">ID</span>, width: 70, formatter: IDFormatter},
        ];

        return (
            <DataGrid
                columns={columns}
                rowGetter={this.getExpenseData}
                rowsCount={this.props.expenses.length}
                onGridRowsUpdated={this.onGridRowsUpdated}
                renderEmpty={this.renderEmpty}
                enableCellSelect={true}
                minHeight={window.innerHeight - 72}
                rowSelection={{
                    showCheckbox: true,
                    enableShiftSelect: true,
                    onRowsSelected: this.onRowsSelected,
                    onRowsDeselected: this.onRowsDeselected,
                    selectBy: {
                        isSelectedKey: 'isSelected',
                    },
                }}
            />
        );
    }
}
