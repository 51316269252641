import React from 'react';


export function nl2br(text) {
    const res = [];
    text.split('\n').forEach((x, i) => {
        if (i !== 0) {
            res.push(<br key={`br-${i}`} />); // eslint-disable-line react/no-array-index-key
        }

        res.push(x);
    });

    return res;
}


export function tokenizeHashtagText(text) {
    const tokens = [];
    function addToken(token) {
    	token = token.trim();
    	if (token.length) {
    		tokens.push(token);
    	}
    }

    let i = 0;
    while (i < text.length) {
        let next = text.indexOf('#', i);
        if (next === -1) {
            addToken(text.substring(i));
        } else {
            addToken(text.substring(i, next));
            i = next;
            next = text.indexOf(' ', i);

            const hashtagText = text.substring(i, next === -1 ? undefined : next);
            addToken(hashtagText);
        }
        i = (next === -1) ? text.length : next;
    }

    return tokens;
}
