import React, {Component} from 'react';


export default class LoginView extends Component {
    render() {
        return (
            <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <h1>Hydrus</h1>

                    <div className="my-5">
                        <h3>Please log in to continue</h3>

                        <button className="btn btn-primary btn-lg my-3" onClick={this.props.onLogin}>Log in with Identity</button>
                    </div>
                </div>
            </div>
        );
    }
}
