export const UnauthorizedException = 'Unauthorized';

import _merge from 'lodash-es/merge';


class ApiBase {
    constructor(auth_flow, url_prefix) {
        this.auth = auth_flow;
        this.url_prefix = url_prefix;
    }

    async request(path, options) {
        const common_options = {
            headers: {
                Authorization: `Bearer ${this.auth.accessToken()}`,
            },
        };

        if (!path.startsWith('https://') && !path.startsWith('http://')) {
            path = this.url_prefix + path;
        }

        const result = await fetch(path, _merge(common_options, options));
        console.log("API request", path, "=>", result.status);
        if (result.status === 401) {
            console.log("Got 401 response", await result.json());
            const shouldRetry = await this.auth.accessTokenExpired();
            if (shouldRetry) {
                console.log("Retying the API request...");
                return await this.request(path, options);
            } else {
                throw UnauthorizedException;
            }
        }

        return result;
    }

    async get(path, options) {
        return await this.request(path, options);
    }

    async post(path, data, options) {
        const post_options = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };
        return await this.request(path, _merge(post_options, options));
    }

    async patch(path, data, options) {
        const patch_options = {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };
        return await this.request(path, _merge(patch_options, options));
    }

    async delete(path, data, options) {
        const delete_options = {
            method: 'DELETE',
        };
        return await this.request(path, _merge(delete_options, options));
    }
}


export default ApiBase;
