import React, {Component} from 'react';

import formatDate from 'date-fns/format'
import isAfter from 'date-fns/is_after'
import isWithinRange from 'date-fns/is_within_range'
import subDays from 'date-fns/sub_days'
import parseDate from 'date-fns/parse'
import _groupBy from 'lodash-es/groupBy';
import _find from 'lodash-es/find'
import _sum from 'lodash-es/sum';
import {Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input} from 'reactstrap';

import BulkEditBar from "../components/BulkEditBar";
import ExpensesList from "../components/ExpensesList";
import {BULK_EDIT_ADD_AS_FIRST_TAG, BULK_EDIT_ADD_AS_LAST_TAG, BULK_EDIT_ADD_AT_BEGINNING} from "../utils/constants";
import {formatMoney} from "../utils/money";


export default class ListView extends Component {
    constructor(props) {
        super(props);

        this.periods = [
            this.createLastNDaysPeriod(7),
            this.createLastNDaysPeriod(30),
            this.createLastNDaysPeriod(90),
            this.createLastNDaysPeriod(180, "Last 6 months"),
            this.createLastNDaysPeriod(365, "Last year"),
            this.createLastNDaysPeriod(3 * 365, "Last 3 years"),
            this.createLastNDaysPeriod(10 * 365, "Last 10 years"),
            {
                id: 'custom',
                label: "Custom",
                since: () => this.state.customPeriodSince,
                until: () => this.state.customPeriodUntil,
            },
        ];

        const activePeriodIdx = this.findActivePeriodIdx(this.periods, props.expenses, 1);
        this.state = {
            isPeriodDropdownOpen: false,
            activePeriodId: this.periods[activePeriodIdx].id,
            filterText: '',
            customPeriodSince: subDays(new Date(), 90),
            customPeriodUntil: new Date(),
            selectedIds: [],
            bulkEditor: {
                isActive: false,
                text: '',
                mode: BULK_EDIT_ADD_AS_LAST_TAG,
            },
        };

        this.setSelectedIds = this.setSelectedIds.bind(this);
        this.onTogglePeriodDropdown = this.onTogglePeriodDropdown.bind(this);
        this.onSetActivePeriod = this.onSetActivePeriod.bind(this);
        this.onFilterTextChanged = this.onFilterTextChanged.bind(this);
        this.onTagClicked = this.onTagClicked.bind(this);
        this.onCustomSinceChanged = this.onCustomSinceChanged.bind(this);
        this.onCustomUntilChanged = this.onCustomUntilChanged.bind(this);
        this.onToggleBulkEditor = this.onToggleBulkEditor.bind(this);
        this.onBulkEditTextChanged = this.onBulkEditTextChanged.bind(this);
        this.onBulkEditModeChanged = this.onBulkEditModeChanged.bind(this);
        this.onBulkEditApply = this.onBulkEditApply.bind(this);
    }

    findActivePeriodIdx(periods, expenses, startIdx=0, minCount=10) {
        // Find earliest period that has at least 10 expenses. Expenses are assumed to be sorted by timestamp.
        const expenseIdx = Math.min(expenses.length - 1, minCount - 1);
        if (expenseIdx < 0) {
            return startIdx;
        }
        for (let i = startIdx; i < periods.length; i++) {
            if (isAfter(expenses[expenseIdx].attributes.timestamp, periods[i].since())) {
                return i;
            }
        }
        return periods.length - 1;
    }

    createLastNDaysPeriod(days, label=null) {
        if (!label) {
            label = `Last ${days} days`;
        }
        return {
            id: `last-${days}-days`,
            label: label,
            since: () => subDays(new Date(), days),
            until: () => (new Date()),
        };
    }

    getFilteredExpenses() {
        const activePeriod = this.getActivePeriod();
        const since = activePeriod.since();
        const until = activePeriod.until();
        this.props.setEarliestRequestedTimestamp(since);

        let expenses = this.props.expenses;

        // Filter by period
        expenses = expenses.filter(expense => {
            return isWithinRange(expense.attributes.timestamp, since, until);
        });

        // Filter by text
        if (this.state.filterText) {
            const tokens = this.state.filterText.toLowerCase().split(/ +/);
            tokens.forEach(token => {
                if (!token.length) {
                    return;
                }
                if (token[0] === '-') {
                    expenses = expenses.filter(expense => expense.attributes.description.toLowerCase().indexOf(token.substr(1)) === -1);
                } else {
                    expenses = expenses.filter(expense => expense.attributes.description.toLowerCase().indexOf(token) !== -1);
                }
            });
        }

        return expenses;
    }

    getEditedDescription(description, addedText, mode) {
        if (mode === BULK_EDIT_ADD_AT_BEGINNING) {
            description = `${addedText} ${description}`;
        } else if (mode === BULK_EDIT_ADD_AS_FIRST_TAG) {
            let pos = description.indexOf('#');
            if (pos === -1) {
                pos = description.length;
            }
            description = `${description.substring(0, pos).trim()} ${addedText} ${description.substring(pos).trim()}`;
        } else if (mode === BULK_EDIT_ADD_AS_LAST_TAG) {
            description = `${description} ${addedText}`;
        }

        return description.trim();
    }

    getEditedExpenses(expenses) {
        const addedText = this.state.bulkEditor.text;
        if (!this.state.bulkEditor.isActive || !addedText) {
            return expenses;
        }

        const mode = this.state.bulkEditor.mode;
        return expenses.map(expense => {
            if (this.state.selectedIds.indexOf(expense.id) === -1) {
                return expense;
            }

            return {
                ...expense,
                attributes: {
                    ...expense.attributes,
                    description: this.getEditedDescription(expense.attributes.description, addedText, mode),
                },
            };
        });
    }

    getActivePeriod() {
        return _find(this.periods, {id: this.state.activePeriodId});
    }

    setSelectedIds(selectedIds) {
        this.setState({selectedIds});
    }

    onTogglePeriodDropdown() {
        this.setState({isPeriodDropdownOpen: !this.state.isPeriodDropdownOpen});
    }

    onSetActivePeriod(periodId) {
        console.log("onSetActivePeriod()", periodId);
        this.setState({activePeriodId: periodId});
    }

    onFilterTextChanged(e) {
        console.log("onFilterTextChanged()", e.target.value, arguments);
        this.setState({filterText: e.target.value});
    }

    onTagClicked(tag) {
        if (this.state.filterText.indexOf(tag) === -1) {
            this.setState({filterText: `${this.state.filterText} ${tag}`.trim()});
        } else {
            this.setState({filterText: this.state.filterText.replace(tag, '').trim()});
        }
    }

    onCustomSinceChanged(e) {
        this.setState({customPeriodSince: parseDate(e.target.value)})
    }

    onCustomUntilChanged(e) {
        this.setState({customPeriodUntil: parseDate(e.target.value)})
    }

    onToggleBulkEditor(e) {
        this.setState({bulkEditor: {...this.state.bulkEditor, isActive: !this.state.bulkEditor.isActive}});
        e.preventDefault();
    }

    onBulkEditTextChanged(e) {
        this.setState({bulkEditor: {...this.state.bulkEditor, text: e.target.value}});
    }

    onBulkEditModeChanged(mode) {
        this.setState({bulkEditor: {...this.state.bulkEditor, mode}});
    }

    onBulkEditApply(e) {
        // Turn off the bulk editor (but keep the rest of the state)
        this.setState({bulkEditor: {...this.state.bulkEditor, isActive: false}});

        // If there's nothing to do, return early
        const addedText = this.state.bulkEditor.text;
        if (!this.state.bulkEditor.isActive || !addedText) {
            return;
        }

        // Figure out the expenses we'll need to change
        const expenses = this.getFilteredExpenses().filter(expense => {
            return this.state.selectedIds.indexOf(expense.id) !== -1;
        });
        console.log("Will change", expenses.length, "expenses");

        // Make the edits
        const mode = this.state.bulkEditor.mode;
        expenses.forEach(expense => {
            this.props.onEditExpense(expense.id, {
                description: this.getEditedDescription(expense.attributes.description, addedText, mode),
            });
        });
    }

    renderFilter() {
        const activePeriod = this.getActivePeriod();
        const periodChoices = this.periods.map(period => {
            return (
                <DropdownItem onClick={() => this.onSetActivePeriod(period.id)}>{period.label}</DropdownItem>
            );
        });
        let customPeriodConfig = null;
        if (activePeriod.id === 'custom') {
            customPeriodConfig = (
                <div className="d-inline-block mx-1">
                    <input
                        className="form-control"
                        type="date"
                        value={formatDate(activePeriod.since(), 'YYYY-MM-DD')}
                        onChange={this.onCustomSinceChanged}
                    />
                    -
                    <input
                        className="form-control"
                        type="date"
                        value={formatDate(activePeriod.until(), 'YYYY-MM-DD')}
                        onChange={this.onCustomUntilChanged}
                    />
                </div>
            );
        }

        return (
            <div className="d-inline-block form-inline ml-4" style={{position: 'relative', top: -4}}>
                <Dropdown isOpen={this.state.isPeriodDropdownOpen} toggle={this.onTogglePeriodDropdown} className="d-inline-block ml-3">
                    <DropdownToggle color="default" caret>
                        {activePeriod.label}
                    </DropdownToggle>
                    <DropdownMenu>
                        {periodChoices}
                    </DropdownMenu>
                </Dropdown>
                {customPeriodConfig}

                <Input className="ml-3" type="search" placeholder="Filter" onChange={this.onFilterTextChanged} value={this.state.filterText} />

                <Button color="default" className="ml-3" onClick={this.onToggleBulkEditor}>Edit</Button>
            </div>
        );
    }

    renderTotals(expenses) {
        const expensesCount = expenses.length;
        if (!expensesCount) {
            return null;
        }

        const expensesByCurrency = _groupBy(expenses, 'attributes.currency');
        let currencyStats = [];
        Object.keys(expensesByCurrency).forEach(currency => {
            const expensesGroup = expensesByCurrency[currency];
            const amounts = expensesGroup.map(expense => parseFloat(expense.attributes.amount));
            currencyStats.push({
                currency: currency,
                count: expensesGroup.length,
                amountPositive: _sum(amounts.filter(amount => (amount > 0))),
                amountNegative: _sum(amounts.filter(amount => (amount < 0))),
                amountTotal: _sum(amounts),
            });
        });

        const summaries = currencyStats.map(stats => {
            const currency = stats.currency;
            if (!stats.amountPositive) {
                return formatMoney(stats.amountNegative, currency);
            } else if (!stats.amountNegative) {
                return formatMoney(stats.amountPositive, currency);
            } else {
                const amountPositiveStr = formatMoney(stats.amountPositive, currency, '');
                const amountNegativeStr = formatMoney(Math.abs(stats.amountNegative), currency, '');
                const amountTotalStr = formatMoney(stats.amountTotal, currency);

                return `${amountPositiveStr} - ${amountNegativeStr} = ${amountTotalStr}`;
            }
        });

        return (
            <div className="float-right">
                {summaries}
            </div>
        );
    }

    render() {
        const expenses = this.getEditedExpenses(this.getFilteredExpenses());

        let bulkEditBar = null;
        if (this.state.bulkEditor.isActive) {
            bulkEditBar = (
                <BulkEditBar
                    {...this.state.bulkEditor}
                    onTextChanged={this.onBulkEditTextChanged}
                    onModeChanged={this.onBulkEditModeChanged}
                    onApply={this.onBulkEditApply}
                />
            );
        }

        return (
            <div>
                <div className="mx-3">
                    <h2 className="d-inline-block">Expenses</h2>
                    {this.renderFilter()}
                    {this.renderTotals(expenses)}
                </div>
                {bulkEditBar}

                <ExpensesList
                    expenses={expenses}
                    selectedIds={this.state.selectedIds}
                    setSelectedIds={this.setSelectedIds}
                    onTagClicked={this.onTagClicked}
                    onEditExpense={this.props.onEditExpense}
                />
            </div>
        );
    }
}
