import React, {Component} from 'react';

import DataGrid from "./DataGrid";


function RemoveFormatter({row}) {
    return (
        <a href="#" onClick={e => row.onDelete(e)}>RM</a>
    );
}


export class RulesList extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.dataGridRef = React.createRef();

        this.getRuleData = this.getRuleData.bind(this);
        this.onGridRowsUpdated = this.onGridRowsUpdated.bind(this);
        this.onDeleteRule = this.onDeleteRule.bind(this);
        this.renderEmpty = this.renderEmpty.bind(this);
    }

    getRuleData(index) {
        const rule = this.props.rules[index];
        return {
            ...rule.attributes,
            onDelete: e => this.onDeleteRule(e, rule.id),
        };
    }

    openCellEditor(row, col) {
        this.dataGridRef.current.openCellEditor(row, col);
    }

    onGridRowsUpdated({fromRow, toRow, updated}) {
        this.props.onEditRule(this.props.rules[fromRow].id, updated);
    }

    onDeleteRule(e, ruleId) {
        e.preventDefault();
        this.props.onDeleteRule(ruleId);
    }

    renderEmpty() {
        return <div>Nothing here</div>;
    }

    render() {
        const columns = [
            {key: "priority", name: "Priority", width:80, editable: true, sortable: true},
            {key: "regex", name: "Regex", editable: true, sortable: true},
            {key: "additional_description", name: "Description", editable: true, sortable: true},
            {key: "remove", name: "", width: 45, formatter: RemoveFormatter},
        ];

        return (
            <DataGrid
                ref={this.dataGridRef}
                columns={columns}
                rowGetter={this.getRuleData}
                rowsCount={this.props.rules.length}
                onGridRowsUpdated={this.onGridRowsUpdated}
                onOrderingChanged={this.props.onOrderingChanged}
            />
        );
    }
}
