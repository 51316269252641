import React from 'react';
import {FormFeedback, FormGroup, Input, Label, CustomInput} from "reactstrap";
import {Field} from 'formik';


export function ReactstrapFormikInput({
        field: {...fields},
        form: {touched, errors, ...rest},
        inputComponent,
        onChange,
        ...props
}) {
    if (!onChange) {
        onChange = fields.onChange;
    }
    if (!inputComponent) {
        const isInvalid = Boolean(touched[fields.name] && errors[fields.name]);
        const value = props.type === 'file' ? undefined : fields.value;
        inputComponent = <Input {...props} {...fields} invalid={isInvalid} onChange={onChange} value={value} />;
    }
    return (
        <FormGroup>
            <Label for={fields.name} className={"label-color"}>{props.label}</Label>
            {inputComponent}
            {touched[fields.name] && errors[fields.name] ? <FormFeedback>{errors[fields.name]}</FormFeedback> : ''}
        </FormGroup>
    );
}


export function ReactstrapFormikRadioInput({
        field: {...fields},
        form: {touched, errors, ...rest},
        options,
        ...props
}) {
    const radioButtons = options.map(({value, label}) => {
        return <CustomInput {...props} {...fields} type="radio" value={value} id={`${fields.name}--${value}`} label={label} />;
    });
    return (
        <FormGroup>
            <Label for={fields.name} className={"label-color"}>{props.label}</Label>
            {radioButtons}
            {touched[fields.name] && errors[fields.name] ? <FormFeedback>{errors[fields.name]}</FormFeedback> : ''}
        </FormGroup>
    );
}


export function ReactstrapTextField(props) {
    return (
        <Field
            type={props.type || "text"}
            component={ReactstrapFormikInput}
            {...props}
        />
    );
}


export function ReactstrapEmailField(props) {
    return (
        <ReactstrapTextField
            type="email"
            {...props}
        />
    );
}


export function ReactstrapNumberField(props) {
    return (
        <ReactstrapTextField
            type="number"
            {...props}
        />
    );
}


export function ReactstrapFileField(props) {
    function onChange(e) {
        // Grab the actual File object and use that (not the filename) as form's value
        props.setFieldValue(props.name,  e.currentTarget.files[0]);
    }
    return (
        <ReactstrapTextField
            type="file"
            onChange={onChange}
            {...props}
        />
    );
}


export function ReactstrapRadioField(props) {
    return (
        <Field
            type={"radio"}
            component={ReactstrapFormikRadioInput}
            {...props}
        />
    );
}
