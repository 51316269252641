import React, {Component} from 'react';
import ReactDataGrid from "react-data-grid";


export default class DataGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editingRow: null,
            gridHeight: 500,
        };

        this.dataGridRef = React.createRef();
        this.wrapperElem = null;
        this.setWrapperRef = element => {
            this.wrapperElem = element;
            this.updateWindowSize();
        };


        this.updateWindowSize = this.updateWindowSize.bind(this);
        this.getRowData = this.getRowData.bind(this);
        this.onGridSort = this.onGridSort.bind(this);
    }

    updateWindowSize() {
        if (!this.wrapperElem) {
            return;
        }
        this.setState({
            gridWidth: window.innerWidth,
            gridHeight: window.innerHeight - this.wrapperElem.offsetTop,
        });
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowSize);
    }

    getRowData(index) {
        if (index < 0 || index >= this.props.rowsCount) {
            return null;
        }
        return this.props.rowGetter(index);
    }

    openCellEditor(row, col) {
        this.dataGridRef.current.openCellEditor(row, col);
    }

    onGridSort(sortColumn, sortDirection) {
        if (sortDirection === 'NONE') {
            this.props.onOrderingChanged(null);
        } else {
            this.props.onOrderingChanged([sortColumn, sortDirection.toLowerCase()]);
        }
    }

    renderEmpty() {
        if (this.props.renderEmpty) {
            return this.props.renderEmpty();
        }
        return (
            <p className="lead text-center m-5">No data</p>
        );
    }

    renderGrid() {
        return (
            <ReactDataGrid
                {...this.props}
                ref={this.dataGridRef}
                rowGetter={this.getRowData}
                enableCellSelect={true}
                minHeight={this.state.gridHeight}
                onGridSort={this.onGridSort}
            />
        );
    }

    render() {
        return (
            <div ref={this.setWrapperRef}>
                {this.props.rowsCount > 0 ? this.renderGrid() : this.renderEmpty()}
            </div>
        );
    }
}
