import React, {Component} from 'react';

import {Formik, Field, Form, ErrorMessage} from 'formik';

import {ReactstrapFileField, ReactstrapNumberField, ReactstrapRadioField, ReactstrapTextField} from "../forms/fields";

import {
    FormGroup,
    Label,
    Input,
    FormText,
    Button,
    CustomInput,
} from 'reactstrap';


export default class ImportView extends Component {
    FILE_TYPE_OPTIONS = [
        {value: 'expenses-hydrus', label: "Expenses from Hydrus"},
        {value: 'expenses-lhv', label: "Expenses from LHV"},
        {value: 'rules-hydrus', label: "Rules from Hydrus"},
    ];
    constructor(props) {
        super(props);

        this.state = {
            finished: false,
            result: null,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    async onSubmit(values, actions) {
        console.log("Submit:", values);
        const result = await this.props.onImportData(values);
        console.log("onSubmit(): result:", result);
        const resultData = await result.json();
        // TODO: error handling

        actions.setSubmitting(false);
        this.setState({finished: true, result: resultData});
    }

    renderForm({errors, touched, isSubmitting, status, setFieldValue}) {
        return (
            <Form>
                <ReactstrapRadioField name="type" label="File type" options={this.FILE_TYPE_OPTIONS} />
                <ReactstrapFileField name="file" label="File" setFieldValue={setFieldValue} />

                {status && status.msg && <p>{status.msg}</p>}
                <Button type="submit" color="primary" disabled={isSubmitting}>
                    Start import
                </Button>
            </Form>
        );
    }

    renderFormik() {
        return (
            <div>
                <h2>Import Data</h2>

                <Formik
                    initialValues={{}}
                    onSubmit={this.onSubmit}
                    render={this.renderForm}
                />
            </div>
        );
    }

    renderSuccess() {
        return (
            <div>
                <h2>Import Completed</h2>

                <p>
                    Your import has succesfully finished!
                </p>
                <p dangerouslySetInnerHTML={{__html: this.state.result.data.summary}}></p>
            </div>
        );
    }

    render() {
        return (
            <div className="container">
                {this.state.finished ? this.renderSuccess() : this.renderFormik()}
            </div>
        );
    }
}
