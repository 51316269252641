import ApiBase from "./api";


class HydrusApi extends ApiBase {
    constructor(auth, url_prefix) {
        super(auth, url_prefix + '/api/2018-10-01');
    }

    userinfo() {
        return this.get('/me');
    }

    addExpense(data) {
        return this.post('/expenses/', {
            data: {
                type: 'expense',
                attributes: data,
            },
        });
    }

    updateExpense(expenseId, attrs) {
        return this.patch(`/expenses/${expenseId}/`, {
            data: {
                type: 'expense',
                id: expenseId,
                attributes: attrs,
            },
        });
    }

    importData(formData) {
        const options = {
            method: 'POST',
            body: formData,
        };
        return this.request('/import/', options);
    }

    expenses(cursor) {
        return this.get(cursor || '/expenses/');
    }

    rules() {
        return this.get('/rules/');
    }

    createRule(attrs) {
        return this.post('/rules/', {
            data: {
                type: 'expense_rule',
                attributes: attrs,
            },
        });
    }

    updateRule(ruleId, attrs) {
        return this.patch(`/rules/${ruleId}/`, {
            data: {
                type: 'expense_rule',
                id: ruleId,
                attributes: attrs,
            },
        });
    }

    deleteRule(ruleId) {
        return this.delete(`/rules/${ruleId}/`);
    }
}


export default HydrusApi;
